import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import tw from "twin.macro";

import "./index.css";
import img1 from "./imgHold/1.png";
import img2 from "./imgHold/2.png";
import img3 from "./imgHold/3.png";
import img4 from "./imgHold/4.png";
import img5 from "./imgHold/5.png";
import img6 from "./imgHold/6.png";

import ClipLoader from "react-spinners/ClipLoader";
import React, { useEffect, useState } from "react";

// 23503e - blue
// d2953c - orange
// 23503e - green

const AppContainer = tw.div`
w-full
h-full
overflow-hidden 
`;

const Footer = tw.h3`
flex
text-center
justify-center
w-full
font-[Regular]
text-black
pt-10
p-8
text-[15px]`;

function Sliding() {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);

  useEffect(() => {
    if (emblaApi) {
      console.log(emblaApi.slideNodes()); // Access API
    }
  }, [emblaApi]);
  return (
    <div className="overflow-hidden" ref={emblaRef}>
      <div className="embla__slide flex">
        <img
          className="w-screen md:h-[85vh] h-[65vh] object-cover"
          src={img1}
        />
        <img
          className="w-screen md:h-[85vh] h-[65vh] object-cover"
          src={img2}
        />
        <img
          className="w-screen md:h-[85vh] h-[65vh] object-cover"
          src={img3}
        />
        <img
          className="w-screen md:h-[85vh] h-[65vh] object-cover"
          src={img4}
        />
        <img
          className="w-screen md:h-[85vh] h-[65vh] object-cover"
          src={img5}
        />
        <img
          className="w-screen md:h-[85vh] h-[65vh] object-cover"
          src={img6}
        />
      </div>
    </div>
  );
}

export default Sliding;
