import "./App.css";
import styled from "styled-components";
import Helmet from "react-helmet";

import { useMediaQuery } from "react-responsive";

import React, { useState, useRef, useEffect } from "react";

import tw from "twin.macro";

import RollerHire from "./Pages/RollerHire";
import Map from "./Pages/Map";

import rollerText from "./Components/ImgSlider/imgHold/justWords.png";
import rollerImg from "./Components/ImgSlider/imgHold/justSkates.png";
import Sliding from "./Components/ImgSlider/index";

import { useParallax } from "react-scroll-parallax";
import Nav from "./Components/Nav";
import Packages from "./Pages/Packages";
import Gallery from "./Pages/Gallery";
import Hire from "./Pages/Hire";

//Colour Pallette

// offWhite #fffffe
// Blue #5398c4
// Pink #c23f82
// Yellow #fcf150
// Black #131313

const AppContainer = tw.div`
flex
flex-col
relative
w-full
justify-center
overflow-hidden`;

const Footer = tw.h3`
flex
text-center
justify-center
w-full
font-[Regular]
text-black
pt-10
p-8
text-[15px]`;

const LogoWrap = tw.div`
        w-screen
        h-screen
        absolute
        flex
        justify-center
        items-center`;

function App() {
  const parallax = useParallax({
    rotate: [-360, 360],
  });

  const parallax1 = useParallax({
    rotate: [360, -360],
  });

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div>
      <Nav />
      <Helmet bodyAttributes={{ style: "background-color : #131313" }} />
      <AppContainer>
        <Sliding className="z-0 absolute top-0" />
        <LogoWrap>
          <img
            ref={parallax.ref}
            className="z-10 absolute md:max-w-[40vw] max-w-[80vw]"
            src={rollerText}
          />
          <img
            ref={parallax1.ref}
            className="z-10 absolute md:max-w-[40vw] max-w-[80vw]"
            src={rollerImg}
          />
        </LogoWrap>
      </AppContainer>
      <RollerHire />
      <Map />
      <Packages />
      <Gallery />
      <Hire />
      {/* <Footer>© Phiof Digital 2024</Footer> */}
    </div>
  );
}

export default App;
