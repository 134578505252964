import "../../App.css";
import tw from "twin.macro";

import discoBall from "../../Components/ImgSlider/imgHold/discoBall.png";
import rollerSkate from "../../Components/ImgSlider/imgHold/skateNoWheel.png";
import wheelL from "../../Components/ImgSlider/imgHold/leftWheel.png";
import wheelR from "../../Components/ImgSlider/imgHold/rightWheel.png";
import whistle from "../../Components/ImgSlider/imgHold/whistle.png";
import blowMiddle from "../../Components/ImgSlider/imgHold/blowMiddle.png";
import blowBottom from "../../Components/ImgSlider/imgHold/blowBottom.png";
import blowTop from "../../Components/ImgSlider/imgHold/blowTop.png";

import Carousel from "react-multi-carousel";
import "./styles.css";

// 5ab9a9 - blue
// d2953c - orange
// 23503e - green

const MainContainer = tw.div`
flex
flex-col
relative
w-full
h-full
text-left
pb-10
bg-[#131313]
overflow-visible`;

const Card = tw.div`
flex flex-col overflow-hidden bg-white w-[300px] h-[600px] place-items-start text-left`;

const CardTitle = tw.h4`
z-0
font-[Fjalla]
font-black
text-[#131313]
text-[50px]
pl-[20px]`;

const CardBody = tw.h5`
z-0
font-[Deux]
font-light
text-[#131313]
text-[25px]
pl-[20px]
pr-[20px]
leading-none`;

const CardButton = tw.button`
z-0
font-[Deux]
font-normal
text-[#131313]
text-[25px]
pl-[20px]
pr-[20px]
underline
hover:no-underline	
cursor-pointer`;

const Title = tw.h1`
z-0
font-[Fjalla]
text-[#fffffe]
md:text-[11vh]
text-[8vh]
pl-[20px]`;

const Headline = tw.h2`
z-0
font-[Fjalla]
text-[#fffffe]
md:text-[60px]
text-[30px]
leading-tight	
pt-10
text-center
pl-[11vw]
pr-[11vw]`;

const Body = tw.h3`
z-0
font-[Deux]
font-light
text-[#fffffe]
md:text-[40px]
text-[30px]
leading-none
pt-10
text-center
pl-[11vw]
pr-[11vw]`;

const MarqueeText = tw.div`

font-[Regular]
text-[#23503e]
text-[3vh]`;

const Text2 = tw.div`
z-0
absolute
font-[Regular]
text-black
sm:tracking-[20px]
sm:text-[30vh]
md:text-[18vh]
text-[13vh]
sm:pt-[35vh]
md:pt-[28vh]
pt-[20vh]`;

const WidthRestrict = tw.div`
z-0
absolute
w-[80vw]`;

const Border = tw.div`
border
border-solid
border-black
rounded-full
bg-black
pt-4
pb-4
overflow-clip`;

function RollerHire() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  //   const disco = useParallax({
  //     rotate: [-270, 180],
  //   });
  //   const wheel1 = useParallax({
  //     rotate: [-360, 360],
  //   });
  //   const wheel2 = useParallax({
  //     rotate: [0, 360],
  //   });

  //   const middle = useParallax({
  //     easing: [0.8, -1, 0.8, 1.8],
  //     translateX: [-10, 60],
  //   });

  //   const bottom = useParallax({
  //     easing: [0.8, -0.8, 0.8, 1.8],
  //     translateX: [-8, 100],
  //     translateY: [-3, 50],
  //   });

  //   const top = useParallax({
  //     easing: [0.8, -0.8, 0.8, 1],
  //     translateX: [0, 100],
  //     translateY: [0, -30],
  //   });
  return (
    <div>
      <MainContainer>
        <Title>THE MOBILE CREW</Title>
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="flex w-full h-full justify-center pt-10 pb-10"
        >
          <Card>
            <div className="w-full h-[325px] overflow-hidden bg-[#5398c4]">
              <img className="h-full w-full object-cover" src={discoBall} />
            </div>
            <CardTitle>FUNK-PACKAGES</CardTitle>
            <CardBody>
              Mobile disco packages from DJ to PA and lighting.
            </CardBody>
            <CardButton onClick={console.log("hello")}>
              We've got you covered.
            </CardButton>
          </Card>
          <Card>
            <div className="relative w-full h-[325px] bg-[#c23f82]">
              <img
                className="h-full w-full object-cover absolute"
                src={rollerSkate}
              />
              <img
                className="h-full w-full top-[80px] right-[38px] object-cover absolute"
                src={wheelL}
              />
              <img
                className="h-full w-full top-[80px] left-[35px] object-cover absolute"
                src={wheelR}
              />
            </div>
            <CardTitle>FUNK-HIRE</CardTitle>
            <CardBody>
              Ranging from Skates and Safety gear to Fully mobile indoor/outdoor
              Roller Rinks.
            </CardBody>
            <CardButton onClick={console.log("hello")}>
              Get a quote today.
            </CardButton>
          </Card>
          <Card>
            <div className="relative w-full h-[325px] bg-[#fcf150]">
              <img
                className="h-full w-full object-cover absolute z-10"
                src={whistle}
              />
              <img
                className="h-full w-full object-cover absolute z-0"
                src={blowMiddle}
              />
              <img
                className="h-full w-full object-cover absolute z-0"
                src={blowBottom}
              />
              <img
                className="h-full w-full object-cover absolute z-0"
                src={blowTop}
              />
            </div>
            <CardTitle>FUNK-COACHING</CardTitle>
            <CardBody>DBS checked and First aid trained Coaches.</CardBody>
            <CardButton onClick={console.log("hello")}>Read more.</CardButton>
          </Card>
        </Carousel>
        <Headline>
          <span className="text-[#5398c4]">Superfunk Roller Disco</span> brings
          the nostalgia of the <span className="text-[#c23f82]">70s</span> and{" "}
          <span className="text-[#c23f82]">80s</span> right to your doorstep,
          transforming <span className="text-[#fcf150]">any</span> space into a
          vibrant roller disco.
        </Headline>
        <Body>
          As the <span className="text-[#5398c4]">pioneers</span> of the mobile{" "}
          <span className="text-[#c23f82]">roller rink</span> experience, we're
          dedicated to reigniting the spark of this{" "}
          <span className="text-[#fcf150]">cult classic</span> activity.
        </Body>
        <Body>
          Whether it's a <span className="text-[#5398c4]">community</span>{" "}
          event, a <span className="text-[#c23f82]">private</span> party, or a{" "}
          <span className="text-[#fcf150]">corporate</span> gathering, our team
          ensures a funky, unforgettable skating experience equipped with all
          the disco era charms and today’s latest hits.{" "}
          <button className="text-[#5398c4] underline hover:no-underline">
            Contact Us
          </button>{" "}
          to swirl and whirl in style — because the disco ball never stops
          spinning when Superfunk Roller Disco is in town!
        </Body>
      </MainContainer>
    </div>
  );
}

export default RollerHire;
