import "./index.css";

import React, { useState, useEffect } from "react";

import tw from "twin.macro";

import { useMediaQuery } from "react-responsive";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

//Colour Pallette

// offWhite #fffffe
// Blue #5398c4
// Pink #c23f82
// Yellow #fcf150
// Black #131313

const AppContainer = tw.div`
flex
flex-col
relative
w-full
justify-center
overflow-hidden`;

const Footer = tw.h3`
flex
text-center
justify-center
w-full
font-[Regular]
text-black
pt-10
p-8
text-[15px]`;

const LogoWrap = tw.div`
        w-screen
        h-screen
        absolute
        flex
        justify-center
        items-center`;

function Nav() {
  const [header, setHeader] = useState("header");
  const [click, setClick] = useState(false);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div>
      {isDesktopOrLaptop && (
        <header className={header}>
          <ul className="links">
            <li className="link-item">HOME</li>
            <li className="link-item">PACKAGES</li>
            <li className="link-item">GALLERY</li>
            <li className="link-item">HIRE</li>
            <li className="link-item">COACHING</li>
          </ul>
        </header>
      )}
      {isTabletOrMobile && (
        <div>
          {click ? (
            <div
              className="
              flex
flex-col
fixed
z-40
bg-black
w-screen
h-screen
justify-center
scroll-hidden
transition-all
ease-in-out
overflow-hidden"
            >
              <div
                className="flex
flex-row
top-0
fixed
z-50
pt-5
pr-5
w-screen
h-[100px]
justify-end
transition-all
ease-in-out
overflow-hidden"
              >
                <div
                  onClick={() => {
                    setClick(false);
                  }}
                >
                  <MenuOpenIcon
                    sx={{
                      fontSize: 80,
                      color: "white",
                      stroke: "white",
                      "stroke-width": 1,
                    }}
                  />
                </div>
              </div>
              <div
                className="flex
                flex-col
relative
w-screen
h-screen
bg-[#131313]
justify-center
content-center]
transition-all
ease-in-out
overflow-hidden"
              >
                <button
                  className="
                    font-[Fjalla]
                    text-[#fffffe]
                    text-[5vw]"
                >
                  HOME
                </button>
                <button
                  className="
                    font-[Fjalla]
                    text-[#fffffe]
                    text-[5vw]"
                >
                  PACKAGES
                </button>
                <button
                  className="
                    font-[Fjalla]
                    text-[#fffffe]
                    text-[5vw]"
                >
                  GALLERY
                </button>
                <button
                  className="
                    font-[Fjalla]
                    text-[#fffffe]
                    text-[5vw]"
                >
                  HIRE
                </button>
                <button
                  className="
                    font-[Fjalla]
                    text-[#fffffe]
                    text-[5vw]"
                >
                  COACHING
                </button>
              </div>
            </div>
          ) : (
            <div
              className="
                flex
                flex-row
                z-30
                fixed
                w-full
                justify-end
                transition-all
                ease-in-out
                pt-5
                pr-5"
            >
              <div
                onClick={() => {
                  setClick(true);
                }}
              >
                <MenuIcon
                  sx={{
                    fontSize: 80,
                    color: "white",
                    stroke: "white",
                    "stroke-width": 1,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Nav;
