import tw from "twin.macro";

import Carousel from "react-multi-carousel";
import "../RollerHire/styles.css";

// 5ab9a9 - blue
// d2953c - orange
// 23503e - green

const MainContainer = tw.div`
flex
flex-col
relative
w-full
h-full
text-left
bg-[#131313]
overflow-visible`;

const Card = tw.div`
flex flex-col overflow-hidden bg-white w-[300px] h-[600px] place-items-start text-left`;

const CardTitle = tw.h4`
z-0
font-[Fjalla]
font-black
text-[#131313]
text-[50px]
pl-[20px]`;

const CardBody = tw.h5`
z-0
font-[Deux]
font-light
text-[#131313]
text-[25px]
pl-[20px]
pr-[20px]
leading-none`;

const CardButton = tw.button`
z-0
font-[Deux]
font-normal
text-[#131313]
text-[25px]
pl-[20px]
pr-[20px]
underline
hover:no-underline	
cursor-pointer`;

const Title = tw.h1`
z-0
font-[Fjalla]
text-[#fffffe]
md:text-[11vh]
text-[8vh]
pl-[20px]`;

const Headline = tw.h2`
z-0
font-[Fjalla]
text-[#fffffe]
md:text-[60px]
text-[30px]
leading-tight	
pt-10
text-center
pl-[11vw]
pr-[11vw]`;

const Body = tw.h3`
z-0
font-[Deux]
font-light
text-[#fffffe]
md:text-[40px]
text-[30px]
leading-none
pt-10
text-center
pl-[11vw]
pr-[11vw]`;

const MarqueeText = tw.div`

font-[Regular]
text-[#23503e]
text-[3vh]`;

const Text2 = tw.div`
z-0
absolute
font-[Regular]
text-black
sm:tracking-[20px]
sm:text-[30vh]
md:text-[18vh]
text-[13vh]
sm:pt-[35vh]
md:pt-[28vh]
pt-[20vh]`;

const WidthRestrict = tw.div`
z-0
absolute
w-[80vw]`;

const Border = tw.div`
border
border-solid
border-black
rounded-full
bg-black
pt-4
pb-4
overflow-clip`;

function Packages() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  //   const disco = useParallax({
  //     rotate: [-270, 180],
  //   });
  //   const wheel1 = useParallax({
  //     rotate: [-360, 360],
  //   });
  //   const wheel2 = useParallax({
  //     rotate: [0, 360],
  //   });

  //   const middle = useParallax({
  //     easing: [0.8, -1, 0.8, 1.8],
  //     translateX: [-10, 60],
  //   });

  //   const bottom = useParallax({
  //     easing: [0.8, -0.8, 0.8, 1.8],
  //     translateX: [-8, 100],
  //     translateY: [-3, 50],
  //   });

  //   const top = useParallax({
  //     easing: [0.8, -0.8, 0.8, 1],
  //     translateX: [0, 100],
  //     translateY: [0, -30],
  //   });
  return (
    <div>
      <MainContainer>
        <Title>FUNK-PACKAGES</Title>
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="flex w-full h-full justify-center pt-10 pb-10"
        >
          <Card>
            <div className="flex w-full h-[325px] overflow-hidden bg-[#131313] border border-5 border-white text-white text-center justify-center items-center">
              img pending
            </div>
            <CardTitle>PACKAGE</CardTitle>
            <CardBody>Package 1 Info Short</CardBody>
            <CardButton onClick={console.log("hello")}>Read More</CardButton>
          </Card>
          <Card>
            <div className="relative w-full h-[325px] bg-[#131313] border border-5 border-white flex text-white text-center justify-center items-center">
              img pending
            </div>
            <CardTitle>PACKAGE</CardTitle>
            <CardBody>Package 2 Info Short</CardBody>
            <CardButton onClick={console.log("hello")}>Read More</CardButton>
          </Card>
          <Card>
            <div className="relative w-full h-[325px] bg-[#131313] border border-5 border-white flex text-white text-center justify-center items-center">
              img pending
            </div>
            <CardTitle>PACKAGE</CardTitle>
            <CardBody>Package 3 Info Short</CardBody>
            <CardButton onClick={console.log("hello")}>Read more.</CardButton>
          </Card>
        </Carousel>
      </MainContainer>
    </div>
  );
}

export default Packages;
