import tw from "twin.macro";

import React, { useState, useEffect } from "react";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  withGoogleMap,
} from "@react-google-maps/api";
// 5ab9a9 - blue
// d2953c - orange
// 23503e - green

const ContainerLeft = tw.div`
flex
md:w-[50vw]
w-[100vw]
h-[400px]
text-center
justify-center
items-center
md:order-first
order-last
overflow-hidden`;

const ContainerRight = tw.div`
flex
md:w-[50vw]
w-[100vw]
h-[400px]
text-center
justify-center
items-center
bg-[#5398c4]
overflow-hidden`;

const Card = tw.div`
flex flex-col overflow-hidden bg-white w-[300px] h-[600px] place-items-start text-left`;

const CardTitle = tw.h4`
z-0
font-[Fjalla]
font-black
text-[#131313]
text-[50px]
pl-[20px]`;

const CardBody = tw.h5`
z-0
font-[Deux]
font-light
text-[#131313]
text-[25px]
pl-[20px]
pr-[20px]
leading-none`;

const CardButton = tw.button`
z-0
font-[Deux]
font-normal
text-[#131313]
text-[25px]
pl-[20px]
pr-[20px]
underline
hover:no-underline	
cursor-pointer`;

const Title = tw.h1`
z-0
font-[Fjalla]
text-[#fffffe]
md:text-[11vh]
text-[8vh]
pl-[20px]`;

const Headline = tw.h2`
z-0
font-[Fjalla]
text-[#fffffe]
md:text-[60px]
text-[30px]
leading-tight	
pt-10
text-center
pl-[11vw]
pr-[11vw]`;

const Body = tw.h3`
z-0
font-[Deux]
font-light
text-[#fffffe]
md:text-[40px]
text-[30px]
leading-none
pt-10
text-center
pl-[11vw]
pr-[11vw]`;

const MarqueeText = tw.div`

font-[Regular]
text-[#23503e]
text-[3vh]`;

const Text2 = tw.div`
z-0
absolute
font-[Regular]
text-black
sm:tracking-[20px]
sm:text-[30vh]
md:text-[18vh]
text-[13vh]
sm:pt-[35vh]
md:pt-[28vh]
pt-[20vh]`;

const WidthRestrict = tw.div`
z-0
absolute
w-[80vw]`;

const Border = tw.div`
border
border-solid
border-black
rounded-full
bg-black
pt-4
pb-4
overflow-clip`;

const center = {
  lat: 53.472591,
  lng: -2.297046,
};

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB3FLMbmZ4zMmX126MeHXsRWy26-iLhcM8",
  });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="flex h-full md:flex-row flex-col">
      <ContainerLeft>
        {isLoaded ? (
          <>
            <div class="w-full h-full ">
              <GoogleMap
                center={center}
                zoom={11}
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                  display: "block",
                }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
              >
                <Marker position={center} place={center} />
              </GoogleMap>
            </div>
          </>
        ) : (
          <></>
        )}
      </ContainerLeft>
      <ContainerRight>
        <Title>FIND US HERE</Title>
      </ContainerRight>
    </div>
  );
}

export default Map;
